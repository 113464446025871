@import url("https://fonts.googleapis.com/css2?family=Muli:wght@300;400;500;600;700;800;900&display=swap");

* {
  margin: 0;
}

body {
  background: white;
  font-family: "Muli", sans-serif;
}

textarea {
  font-family: inherit;
  font-size: inherit;
}

iframe {
  width: 100vw;
  height: 100vh;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}
input:-webkit-autofill {
  -webkit-text-fill-color: rgba(102, 108, 111, 0.9) !important;
}
input:-webkit-autofill::first-line {
  font-weight: 600;
  font-size: 15px;
}
